<template>
  <div
    class="main"
    v-loading="loading"
    element-loading-text="系统分析生成中，请稍后..."
  >
    <heads></heads>
    <banners></banners>
    <div class="content">
      <div class="cons">
        <div class="cons-title">论文拟题</div>
        <div class="cons-form">
          <div class="lunt-xzfs">
            <!-- <div
              :class="
                ischeck == true
                  ? 'lunt-xzfs-list lunt-xzfs-list-active'
                  : 'lunt-xzfs-list lunt-xzfs-list-none'
              "
              @click="
                ischeck = true;
                clearform();
              "
            >
              输入关键词
            </div> -->
            <div
              :class="
                ischeck == false
                  ? 'lunt-xzfs-list lunt-xzfs-list-active'
                  : 'lunt-xzfs-list lunt-xzfs-list-none'
              "
              @click="
                ischeck = false;
                clearform();
              "
            >
              输入语句
            </div>
          </div>

          <el-form
            :model="form"
            label-width="120px"
            :rules="baseRules"
            ref="ruleFormRef"
            v-if="ischeck"
          >
            <div class="custom-block">
              输入研究内容关键词，生成论文标题供参考
            </div>
            <el-form-item label="关键词" required prop="kwword">
              <el-input
                v-model="form.kwword"
                placeholder="请输入关键词，中间以逗号或空格隔开，如：经济，发展，科技。至少需要3个词语"
                @keyup.enter="onSubmit(ruleFormRef)"
              />
              <el-button type="primary" @click="onSubmit(ruleFormRef)"
                >生成标题</el-button
              >
              <!-- <el-button @click="comeback">重新选词</el-button> -->
            </el-form-item>
          </el-form>

          <el-form
            v-if="!ischeck"
            :model="form1"
            label-width="120px"
            :rules="baseRules1"
            ref="ruleFormRef1"
          >
            <div class="custom-block">输入研究内容语句，生成论文标题供参考</div>
            <el-form-item label="输入语句" required prop="kwword">
              <el-input
                v-model="form1.kwword"
                type="textarea"
                :rows="10"
                placeholder="请输入一段话，如：走过春的播种，夏的辛劳，迎来了值得期待的收获之秋。"
                @keyup.ctrl.enter="onSubmit1(ruleFormRef1)"
              />
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSubmit1(ruleFormRef1)"
                >生成标题</el-button
              >
            </el-form-item>
          </el-form>
        </div>
        <div class="xuanti">
          <div class="custom-block">过程分析</div>
          <div class="xuanti-ul">
            <el-table :data="tableData" style="width: 100%" border>
              <el-table-column prop="kw" label="关键词" width="180" />
              <el-table-column prop="tf" label="tf" sortable />
              <el-table-column prop="idf" label="idf" sortable />
              <el-table-column prop="tfidf" label="tfidf" sortable />
            </el-table>
          </div>
        </div>
        <div class="xuanti">
          <div class="custom-block">以下是系统为您生成的题目</div>
          <div class="xuanti-ul">
            <el-input v-model="timulist" :rows="12" type="textarea" />
          </div>
        </div>
      </div>
    </div>
    <footers></footers>
  </div>
</template>

<script setup>
import { post, get } from "@/utils/index";
import { ElMessage } from "element-plus";
import { reactive, ref } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();
let ischeck = ref(false);
// loading
let loading = ref(false);
let form = reactive({
  kwword: "",
});
let form1 = reactive({
  kwword: "",
});
let tableData=reactive([])

// 题目
let timulist = ref("");

const ruleFormRef = ref();
const ruleFormRef1 = ref();

const baseRules = {
  kwword: [{ required: true, message: "请输入关键词", trigger: "blur" }],
};
const baseRules1 = {
  kwword: [{ required: true, message: "请输入一句话", trigger: "blur" }],
};
// 词语
const onSubmit = (formEl) => {
  if (!formEl) return;
  formEl.validate((valid) => {
    // 通过验证规则 true
    if (valid) {
      loading.value = true;

      // console.log(formData)
      post("/journalCommon/GetGenSentences", {
        str: form.kwword,
      }).then((res) => {
        loading.value = false;
        // console.log(res.data);
        timulist.value = res.data;
      });
    }
  });
};
// 清空
const clearform = () => {
  timulist.value = "";
  form.kwword = "";
  form1.kwword = "";
};
// 句子
const onSubmit1 = (formEl) => {
  if (!formEl) return;
  formEl.validate((valid) => {
    // 通过验证规则 true
    if (valid) {
      loading.value = true;
      // console.log(form1);
      post("/journalCommon/SubWordCut", {
        key: form1.kwword,
        kt: "",
        yxkey: "",
      })
        .then((res) => {
          // console.log(res);
          let str = "";
          res.data.forEach((e) => {
            str += e + " ";
          });
          post("/journalCommon/GetGenSentencesByTfidf", {
            str: str,
            sennum: 10,
            wordnum: 10,
          })
            .then((result) => {
              loading.value = false;
              console.log(result);
              timulist.value = result.data.str;
              tableData.length=0
              tableData.push(...result.data.key)
            })
            .catch((err) => {
              loading.value = false;
            });
        })
        .catch((err) => {
          loading.value = false;
        });
    }
  });
};

// 获取传递过来的关键词
const getkey = () => {
  form.kwword = decodeURIComponent(router.currentRoute.value.query.key);
};
// getkey();

// 返回
const comeback = () => {
  router.push({
    path: "/xtzs/xc",
    query: {},
  });
};
</script>

<style scoped>
:deep(.el-form-item__label) {
  background: #f5f7fa;
}
:deep(.el-form-item__content) {
  flex-wrap: nowrap;
}
.main {
  background: #fff;
}
.content {
  width: 1200px;
  height: auto;
  margin: auto;
}
.cons {
  width: 1200px;
  margin: auto;
  padding: 30px 0;
}
.cons-form {
  width: 100%;
}
.cons-title {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  padding: 0 0 10px 0;
}
.cons-subtitle {
  width: 100%;
  padding: 10px 30px;
  box-sizing: border-box;
  background: #f5f7fa;
  font-size: 14px;
  line-height: 20px;
  color: #999;
  margin: auto;
  margin-bottom: 30px;
}
.custom-block {
  padding: 8px 16px;
  background-color: #ecf5ff;
  border-radius: 4px;
  border-left: 3px solid #409eff;
  margin: 15px 0 15px 0;
  font-size: 14px;
}
.con-btn {
  text-align: center;
}

.xuanti {
  margin-top: 15px;
}
.lunt-xzfs {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
}
.lunt-xzfs-list {
  width: 150px;
  height: 40px;
  border: 1px solid #409eff;
  box-sizing: border-box;
  display: flex;
  margin: 0 5px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}
.lunt-xzfs-list-none {
  cursor: pointer;
  background: #fff;
  color: #409eff;
}
.lunt-xzfs-list-none:hover {
  background: #eef7ff;
}
.lunt-xzfs-list-active {
  background: #409eff;
  color: #fff;
  cursor: default;
}
</style>
